<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  nav(aria-label='breadcrumb')
  ol.breadcrumb
    li.breadcrumb-item.active(aria-current='page') Getting started
  BackHelp
  .row
    .col-md-8.offset-md-1.py-5
      .pb-4
        .fs-5.fw-bold 1.4 Subscribing to Kloser

      .pb-5
        p 1. To subscribe to Kloser, go to the “Account” at the bottom tab. Tap on the “Subscription”.
        img(src="/images/help/400.Account – empty – 1.png")
      .pb-5
        p 2. You can choose either a monthly or yearly billing cycle. You could save more if you opt in for a yearly plan.
        img(src="/images/help/695.Subscription-empty.png")
        p 3. Tap on “Start 30 days trial” to proceed.
      .pb-5
        p 4. Select any existing payment method or you can add a new credit/debit card to proceed.
        img(src="/images/help/696.Subscription - choose payment.png")
      .pb-5
        p 5. Tap “Next” to confirm the purchase
        img(src="/images/help/697.Subscription - choose payment – 2.png")
      .pb-5
        p 6. You will receive a confirmation email.
        img(src="/images/help/698. Subscription - status- subscribed.png")

      .pb-5
        .fs-5.fw-bold Note:
        p Your card will only be charged once the 30 days free trial ends. Once the trial ends, you’ll receive an invoice confirming payment to your registered email. Your subscription will automatically renew to ensure uninterrupted access until you cancel.
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "subscribing",
  components: { Footer, Navbar, BackHelp },
};
</script>
